import { ProgressSpinner } from 'primereact/progressspinner'
import { useEffect, useRef, useState } from 'react'
import text from '../../data/text.json'
import Site from '../../api/site'
import { Controller, useForm } from 'react-hook-form'
import { InputText } from 'primereact/inputtext'
import { InputTextarea } from 'primereact/inputtextarea'
import { Button } from 'primereact/button'
import { Card } from 'primereact/card'
import autoAnimate from '@formkit/auto-animate'
import { ReactSortable } from 'react-sortablejs'

const Gallery = () => {
    const [isFetching, setIsFetching] = useState(true)
    const lang = 'az'
    const { control, handleSubmit, reset } = useForm()
    const [loader, setLoader] = useState(false)
    const [file, setFile] = useState(null)
    const [gallery, setGallery] = useState([])
    const containerRef = useRef()
    const [delLoader, setDelLoader] = useState(false)
    const fetchData = async () => {
        setIsFetching(true)
        const data = await Site.getGallery()
        setGallery(data?.reverse())
        setIsFetching(false)
    }

    const create = async (data) => {
        setLoader(true)
        const formData = new FormData()
        formData.append('title', data.title)
        formData.append('body', data.body)
        formData.append('photo', file)
        try {
            await Site.addGallery(formData)
            reset({
                title: '',
                body: ''
            })
            fetchData()
            setFile(null)
        } catch (e) {
            console.log(e)
        } finally {
            setLoader(false)
        }
    }

    const deleteGallery = async (id) => {
        setDelLoader(id)
        await Site.deleteGallery(id)
        setDelLoader(false)
        fetchData()
    }

    const update = async () => {
        await Site.updateGallery({
            sort: gallery.map(item => item?.id)
        })
    }

    useEffect(() => {
        fetchData()
    }, [])

    useEffect(() => {
        containerRef.current && autoAnimate(containerRef.current)
    }, [containerRef])

    return (
        <div className="profile-page">
            {isFetching ? (
                <div className="flex mt-5 justify-content-center align-items-center">
                    <ProgressSpinner />
                </div>
            ) : (
                <div className="content w-full">
                    <p className="page-title">{text[lang].gallery_page_title}</p>
                    <div className="grid">
                        <div className="col-12 md:col-6">
                            <Card>
                                <form onSubmit={handleSubmit(create)}>
                                    <div className="grid">
                                        <div className="col-12 md:col-6">
                                            <label htmlFor="note">{text[lang].title}</label>
                                            <Controller render={({ field: { value, onChange } }) => (
                                                <InputText
                                                    className="w-full"
                                                    value={value}
                                                    onChange={onChange}
                                                    id="name"
                                                    aria-describedby="name"
                                                    placeholder={text[lang].name}
                                                />
                                            )} name="title" control={control} />
                                        </div>
                                    </div>
                                    <div className="grid">
                                        <div className="col-12 md:col-6">
                                            <label htmlFor="note">{text[lang].desc}</label>
                                            <Controller render={({ field: { value, onChange } }) => (
                                                <InputTextarea
                                                    className="w-full"
                                                    value={value}
                                                    onChange={onChange}
                                                    placeholder={text[lang].desc}
                                                />
                                            )} name="body" control={control} />
                                        </div>
                                    </div>
                                    <div className="grid">
                                        <div className="col-12 md:col-6">
                                            <label className="p-button p-button-danger">
                                                <input
                                                    type="file"
                                                    accept=".png,.jpeg,.jpg,.jfif,.mp4,.mov,.avi,.wmv"
                                                    onChange={e => setFile(e.target.files[0])}
                                                    style={{ display: 'none' }}
                                                />
                                                <i className="pi pi-file" />
                                            </label>
                                        </div>
                                    </div>
                                    {file && (
                                        <div className="grid">
                                            <div className="col-12 md:col-6">
                                                {file.type.startsWith('image/') ? (
                                                    <img
                                                        width="100"
                                                        height="100"
                                                        style={{ objectFit: 'cover' }}
                                                        src={URL.createObjectURL(file)}
                                                        alt="Aytən Mirzəliyeva"
                                                    />
                                                ) : (
                                                    <video
                                                        width="100"
                                                        height="100"
                                                        style={{ objectFit: 'cover' }}
                                                        src={URL.createObjectURL(file)}
                                                        controls
                                                    />
                                                )}
                                            </div>
                                        </div>
                                    )}
                                    <div className="grid">
                                        <div className="col-12 md:col-6">
                                            <div className="flex justify-content-end">
                                                <Button className="p-button-danger d-flex align-items-center gap-1"
                                                    disabled={loader}>
                                                    {loader && <i className="pi pi-spin pi-spinner mr-1" />}
                                                    {text[lang].save}
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </Card>
                        </div>
                    </div>
                    <ReactSortable list={gallery} setList={setGallery} className="grid" onChange={update}>
                        {gallery.map(item => (
                            <div style={{ cursor: 'move' }} className="col-12 md:col-6" key={item.id}>
                                <Card title={item.title}>
                                    <div>
                                        {item.photo.endsWith('.mp4') || item.photo.endsWith('.mov') || item.photo.endsWith('.avi') || item.photo.endsWith('.wmv') ? (
                                            <video
                                                style={{ width: '100%', height: 300, objectFit: 'cover' }}
                                                src={process.env.REACT_APP_BASE_API_URL + '/' + item?.photo}
                                                controls
                                                alt={item.title}
                                            />
                                        ) : (
                                            <img
                                                style={{ width: '100%', height: 300, objectFit: 'cover' }}
                                                src={process.env.REACT_APP_BASE_API_URL + '/' + item?.photo}
                                                alt={item.title}
                                            />
                                        )}
                                    </div>
                                    <p className="m-0">
                                        {item.body}
                                    </p>
                                    <div className="mt-2 flex justify-content-end">
                                        <Button disabled={delLoader === item?.id} className="p-button-danger"
                                            onClick={() => deleteGallery(item?.id)}>
                                            {delLoader === item?.id ? <i className="pi pi-spin pi-spinner mr-1" /> :
                                                <i className="pi pi-trash" />}
                                        </Button>
                                    </div>
                                </Card>
                            </div>
                        ))}
                    </ReactSortable>
                </div>
            )}
        </div>
    )
}

export default Gallery
